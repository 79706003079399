import * as React from "react";

import SuperHeader from "../components/superHeader";
import Header from './../components/header';
import InnerLayout from '../components/innerLayout';
import Hero from "../modules/fans/hero";
import Content from '../modules/fans/content';
import Download from "../modules/fans/download";
import Footer from './../components/footer';
import useComposeUrl from "../hooks/useComposeUrl";

const Comparison = ({ location }) => {
  const currentPageUrl = useComposeUrl(location.pathname);

  return (
    <>
      <SuperHeader
        title="Fans / Starbeat"
        metaTitle="Starbeat User Benefits"
        metaDescription="A privacy focused Social Media built around authentic content and personalized conversations, even with your favourite creators!"
        currentUrl={currentPageUrl}
      />
      <Header />
      <InnerLayout>
        <Hero />
        <Content />
        <Download />
      </InnerLayout>
      <Footer />
    </>
  );
};

export default Comparison;
