import React from 'react';

import MultipleWaysIllustrationLight from '../../images/multiple-ways-illustration-light.svg';
import MultipleWaysIllustrationDark from '../../images/multiple-ways-illustration-dark.svg';
import FansStarCoinsIllustrationLight from '../../images/fans-star-coins-illustration-light.svg';
import FansStarCoinsIllustrationDark from '../../images/fans-star-coins-illustration-dark.svg';
import DownloadSignupIllustrationLight from '../../images/download-sign-up-illustration-light.svg';
import DownloadSignupIllustrationDark from '../../images/download-sign-up-illustration-dark.svg';
import ConnectWalletIllustrationLight from '../../images/connect-wallet-illustration-light.svg';
import ConnectWalletIllustrationDark from '../../images/connect-wallet-illustration-dark.svg';
import SubscribeContentIllustrationLight from '../../images/subscribe-content-illustration-light.svg';
import SubscribeContentIllustrationDark from '../../images/subscribe-content-illustration-dark.svg';
import EarnRecognitionIllustrationLight from '../../images/earn-recognition-illustration-light.svg';
import EarnRecognitionIllustrationDark from '../../images/earn-recognition-illustration-dark.svg';

const Content = () => (
  <>
    <section className="py-12 bg-white dark:bg-gray-900 sm:py-16 lg:py-20">
      <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
        <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-24">
          <div className="lg:order-2">
            <img className="block w-full max-w-sm mx-auto dark:hidden" src={MultipleWaysIllustrationLight}
              alt="Multiple ways to connect" />
            <img className="hidden w-full max-w-sm mx-auto dark:block" src={MultipleWaysIllustrationDark}
              alt="Multiple ways to connect" />
          </div>

          <div className="lg:order-1">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
              Multiple ways to connect
            </h2>
            <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200">
              We'll keep adding tons of features that help improve conversations between your friends, creators &
              communities!
            </p>
            <p className="mt-4 text-lg font-medium text-gray-500 dark:text-gray-200">
              Subscribe to creator plans or showcase your creative talent through Stardubs & Auditions!
            </p>
          </div>
        </div>

        <div className="grid items-center grid-cols-1 mt-12 lg:grid-cols-2 gap-y-12 lg:gap-x-24 sm:mt-16 lg:mt-20">
          <div>
            <img className="block w-full max-w-sm mx-auto dark:hidden" src={FansStarCoinsIllustrationLight}
              alt="Fans star coins" />
            <img className="hidden w-full max-w-sm mx-auto dark:block" src={FansStarCoinsIllustrationDark}
              alt="Fans star coins" />
          </div>

          <div>
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
              Star Coins are here
            </h2>
            <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200">
              All transactions on STARBEAT happen in Star Coins which let you subscribe to exclusive content, creator
              perks, purchase
              NFTs and more in a safe & easy way!
            </p>
            <p className="mt-4 text-lg font-medium text-gray-500 dark:text-gray-200">
              All transactions on STARBEAT happen in Star Coins which let you subscribe to exclusive content, creator
              perks, purchase
              NFTs and more in a safe & easy way!
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="py-12 bg-gray-50 dark:bg-gray-800 sm:py-16 lg:py-20">
      <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white">
            Social Media made easy
          </h2>
          <p className="mt-4 text-base font-medium leading-8 text-gray-500 dark:text-gray-400 lg:text-lg">
            Get free from endless loops of low quality content, protect your digital rights & own your data. Engage 1-on-1 with your favourite creators!
          </p>
        </div>

        <div className="grid grid-cols-1 mt-12 gap-y-12 sm:grid-cols-2 lg:grid-cols-4 sm:mt-12 lg:mt-20 gap-x-16">
          <div>
            <img className="block w-48 h-48 mx-auto dark:hidden" src={DownloadSignupIllustrationLight}
              alt="Signup" />
            <img className="hidden w-48 h-48 mx-auto dark:block" src={DownloadSignupIllustrationDark}
              alt="Signup" />
            <h3 className="mt-6 text-lg font-bold text-gray-900 dark:text-white lg:text-xl">
              Download the App & Sign up as a Fan
            </h3>
            <p className="mt-3 text-sm font-medium text-gray-500 dark:text-gray-400">
              Complete your profile and start following your favourite creators for free!
            </p>
          </div>

          <div>
            <img className="block w-48 h-48 mx-auto dark:hidden" src={ConnectWalletIllustrationLight} alt="Activate Wallet" />
            <img className="hidden w-48 h-48 mx-auto dark:block" src={ConnectWalletIllustrationDark} alt="Activate Wallet" />
            <h3 className="mt-6 text-lg font-bold text-gray-900 dark:text-white lg:text-xl">
              Activate your wallet & Add some Star Coins
            </h3>
            <p className="mt-3 text-sm font-medium text-gray-500 dark:text-gray-400">
              Add some Star Coins to subscribe to Creator Plans, get NFTs, or simply reward your favourite content!
            </p>
          </div>

          <div>
            <img className="block w-48 h-48 mx-auto dark:hidden" src={SubscribeContentIllustrationLight}
              alt="Subscribe for exclusive content" />
            <img className="hidden w-48 h-48 mx-auto dark:block" src={SubscribeContentIllustrationDark}
              alt="Subscribe for exclusive content" />
            <h3 className="mt-6 text-lg font-bold text-gray-900 dark:text-white lg:text-xl">
              Subscribe for Exclusive Content
            </h3>
            <p className="mt-3 text-sm font-medium text-gray-500 dark:text-gray-400">
              Subscribe to a Creators plans and help them grow! Creators decide the plan perks and price and all the
              star coins go to
              them!
            </p>
          </div>

          <div>
            <img className="block w-48 h-48 mx-auto dark:hidden" src={EarnRecognitionIllustrationLight}
              alt="Become Top fan" />
            <img className="hidden w-48 h-48 mx-auto dark:block" src={EarnRecognitionIllustrationDark}
              alt="Become Top fan" />
            <h3 className="mt-6 text-lg font-bold text-gray-900 dark:text-white lg:text-xl">
              Earn Recognition and become a top-fan
            </h3>
            <p className="mt-3 text-sm font-medium text-gray-500 dark:text-gray-400">
              Become a creators top-fan simply by interacting with their content or supporting them with Star Coins
              and get 1-on-1
              session!
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Content;
