import React from 'react';

import FansHeroLight from '../../images/fans-hero-light.png';
import FansHeroDark from '../../images/fans-hero-dark.png';

const Hero = () => (
  <section className="bg-white dark:bg-gray-900">
    <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="relative overflow-hidden rounded-3xl bg-secondary-100 dark:bg-secondary-900">
        <div className="relative px-6 py-12 sm:px-12 sm:pt-16 lg:px-16 lg:pt-20 xl:px-20">
          <div className="max-w-3xl mx-auto text-center">
            <p className="text-sm font-bold tracking-widest uppercase text-secondary-500 dark:text-secondary-400">
              ad-free, privacy friendly
            </p>
            <h1
              className="mt-6 text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl lg:text-5xl sm:tracking-tight">
              Conversations Redefined
            </h1>
            <p className="mt-6 text-lg font-medium text-gray-500 dark:text-gray-200 lg:text-xl">
              Experience a social media without ads, repetitive endless content with more control over what you'd
              like to see!
            </p>
            <p className="mt-4 text-lg font-medium text-gray-500 dark:text-gray-200 lg:text-xl">
              Every 💗, share & comment also helps you get closer to getting recognized by your favourite creators!
            </p>
          </div>

          <div className="max-w-4xl mx-auto mt-12 sm:mt-16 lg:mt-20">
            <img className="block w-full mx-auto dark:hidden" src={FansHeroLight} alt="Find your favourite creators through Starbeat" />
            <img className="hidden w-full mx-auto dark:block" src={FansHeroDark} alt="Find your favourite creators through Starbeat" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
