import React from 'react';

import AppStore from '../../images/appstore.png';
import GoogleStore from '../../images/googlestore.png';
import CTAMockupLight from '../../images/cta-mockup-light.png';
import CTAMockupDark from '../../images/cta-mockup-dark.png';

const Download = () => (
  <section id="download-app" className="py-12 sm:py-16 lg:py-20 bg-primary-100 dark:bg-primary-900">
    <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
      <div className="grid items-center grid-cols-1 lg:grid-cols-2 gap-y-16 gap-x-24">
        <div className="text-center lg:text-left">
          <p className="text-sm font-bold tracking-widest uppercase text-primary-500 dark:text-primary-300">
            The Ethical Ad-free social media
          </p>
          <h3 className="mt-6 text-3xl font-bold text-gray-900 dark:text-white">
            Download a new way to be Social!
          </h3>
          <p className="mt-4 text-lg font-medium leading-8 text-gray-500 dark:text-gray-300">
            Have personalized interactions & gain recognition for supporting your favourite creators in a social media space you&apos;re used to!
          </p>

          <div className="flex items-center justify-center mt-8 space-x-6 lg:justify-start lg:mt-12">
            <a href="https://tally.so/r/3NkpQw" title="Download iPhone app" className="inline-flex">
              <img className="object-contain w-auto h-16" src={AppStore} alt="Starbeat Appstore link" />
            </a>

            <a href="https://tally.so/r/3NkpQw" title="Download Android app" className="inline-flex">
              <img className="object-contain w-auto h-16" src={GoogleStore} alt="Starbeat Playstore link" />
            </a>
          </div>
        </div>

        <div>
          <img className="block w-full mx-auto dark:hidden" src={CTAMockupLight} alt="Send gift to your favourite creators" />
          <img className="hidden w-full mx-auto dark:block" src={CTAMockupDark} alt="Send gift to your favourite creators" />
        </div>
      </div>
    </div>
  </section>
);

export default Download;
